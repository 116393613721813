export const indexRoute = "/";
export const sbh2023Route = "/sbh2023";
export const problemStatementsRoute = "/problem-statements";
export const problemStatementsRoute24 = "/problem-statements24";
export const processFlowRoute = "/process-flow";
export const projectImplementationRoute = "/project-implementation";
export const TeamImplementationRoute = "/team-implementation";
export const venueRoute = "/venue";
export const sbhthemesRoute = "/sbh-themes";
export const updatesRoute = "/updates";
export const teamRoute = "/team";
export const prizesRoute = "/prizes";
export const eligibilityRoute = "/eligibility";
export const EmailRoute = "/email";
export const loginRoute = "/login";
export const adminPortalRoute = "/admin-portal";
export const contactUsRoute = "/contact-us";
export const faqRoute = "/faq";
export const rulesRoute = "/rules";
export const resultSeniorRoute = "/result/senior";
export const resultJuniorRoute = "/result/junior";